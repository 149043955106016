<template>
  <v-row>
    <v-col cols="12" sm="6" md="3" lg="3" class="py-0 mb-2">
      <v-card>
        <v-card-text class="pa-0">
          <div class="d-flex align-center">
            <div class="position-relative float-right pt-1">
              <span class="chart-inner-icon text-muted pt-1 subtitle-2">{{ matriz.re.percent }}%</span>
              <vue-apex-charts
                type="donut"
                width="90"
                :options="realizado.chartOptions"
                :series="[parseInt(matriz.re.percent), 100 - parseInt(matriz.re.percent)]"
              ></vue-apex-charts>
            </div>
            <div>
              <h2 class="font-weight-light">{{ matriz.re.count }}</h2>
              <span class="font-weight-bold" style="font-size:16px">{{ matriz.re.title }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="3" lg="3" class="py-0 mb-2">
      <v-card>
        <v-card-text class="pa-0">
          <div class="d-flex align-center">
            <div class="position-relative float-right pt-1">
              <span class="chart-inner-icon text-muted pt-1 subtitle-2">{{ matriz.pv.percent }}%</span>
              <vue-apex-charts
                type="donut"
                width="90"
                :options="porvencer.chartOptions"
                :series="[parseInt(matriz.pv.percent), 100 - parseInt(matriz.pv.percent)]"
              ></vue-apex-charts>
            </div>
            <div>
              <h2 class="font-weight-light">{{ matriz.pv.count }}</h2>
              <span class="font-weight-bold" style="font-size:16px">{{ matriz.pv.title }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="3" lg="3" class="py-0 mb-2">
      <v-card>
        <v-card-text class="pa-0">
          <div class="d-flex align-center">
            <div class="position-relative float-right pt-1">
              <span class="chart-inner-icon text-muted pt-1 subtitle-2">{{ matriz.nr.percent }}%</span>
              <vue-apex-charts
                type="donut"
                width="90"
                :options="norealizado.chartOptions"
                :series="[parseInt(matriz.nr.percent), 100 - parseInt(matriz.nr.percent)]"
              ></vue-apex-charts>
            </div>
            <div>
              <h2 class="font-weight-light">{{ matriz.nr.count }}</h2>
              <span class="font-weight-bold" style="font-size:16px">{{ matriz.nr.title }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" md="3" lg="3" class="py-0 mb-2">
      <v-card>
        <v-card-text class="pa-0">
          <div class="d-flex align-center">
            <div class="position-relative float-right pt-1">
              <span class="chart-inner-icon text-muted pt-1 subtitle-2">{{ matriz.ca.percent }}%</span>
              <vue-apex-charts
                type="donut"
                width="90"
                :options="cancelado.chartOptions"
                :series="[parseInt(matriz.ca.percent), 100 - parseInt(matriz.ca.percent)]"
              ></vue-apex-charts>
            </div>
            <div>
              <h2 class="font-weight-light">{{ matriz.ca.count }}</h2>
              <span class="font-weight-bold" style="font-size:16px">{{ matriz.ca.title }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  name: 'MatrizActasCabeceras',
  components: {
    VueApexCharts
  },
  props: ['matriz'],
  data: () => ({
    fechaActual: new Date(),
    realizado: {
      chartOptions: {
        labels: ['Search Traffic', 'Free'],
        dataLabels: {
          enabled: false
        },
        grid: {
          padding: {
            left: 0,
            right: 0
          }
        },
        chart: {},
        plotOptions: {
          pie: {
            donut: {
              size: '85px'
            }
          }
        },
        stroke: {
          width: 0
        },
        legend: {
          show: false
        },
        colors: ['#27a300', 'rgb(250, 250, 250)'],
        tooltip: {
          enabled: false,
          theme: 'dark'
        }
      }
    },
    norealizado: {
      chartOptions: {
        labels: ['Search Traffic', 'Free'],
        dataLabels: {
          enabled: false
        },
        grid: {
          padding: {
            left: 0,
            right: 0
          }
        },
        chart: {},
        plotOptions: {
          pie: {
            donut: {
              size: '85px'
            }
          }
        },
        stroke: {
          width: 0
        },
        legend: {
          show: false
        },
        colors: ['#d90429', 'rgb(250, 250, 250)'],
        tooltip: {
          enabled: false,
          theme: 'dark'
        }
      }
    },
    cancelado: {
      chartOptions: {
        labels: ['Search Traffic', 'Free'],
        dataLabels: {
          enabled: false
        },
        grid: {
          padding: {
            left: 0,
            right: 0
          }
        },
        chart: {},
        plotOptions: {
          pie: {
            donut: {
              size: '85px'
            }
          }
        },
        stroke: {
          width: 0
        },
        legend: {
          show: false
        },
        colors: ['#343a40', 'rgb(250, 250, 250)'],
        tooltip: {
          enabled: false,
          theme: 'dark'
        }
      }
    },
    porvencer: {
      chartOptions: {
        labels: ['Search Traffic', 'Free'],
        dataLabels: {
          enabled: false
        },
        grid: {
          padding: {
            left: 0,
            right: 0
          }
        },
        chart: {},
        plotOptions: {
          pie: {
            donut: {
              size: '85px'
            }
          }
        },
        stroke: {
          width: 0
        },
        legend: {
          show: false
        },
        colors: ['#02a99e', 'rgb(250, 250, 250)'],
        tooltip: {
          enabled: false,
          theme: 'dark'
        }
      }
    }
  })
};
</script>
